/************************************/
[class^="icon-"], [class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.icon-web-admin:before {
  content: "\ea00";
}
.icon-support-panel:before {
  content: "\ea01";
}
.icon-user-app:before {
  content: "\ea02";
}
.icon-taxi-app:before {
  content: "\ea03";
}
.icon-clock-time:before {
  content: "\e9fb";
}
.icon-doostep-delivery:before {
  content: "\e9fc";
}
.icon-take-away-feature:before {
  content: "\e9fd";
}
.icon-safety-badge:before {
  content: "\e9fe";
}
.icon-web-mob-platform:before {
  content: "\e9ff";
}
.icon-wine_cat:before {
  content: "\e9f1";
}
.icon-construction_cat:before {
  content: "\e9f2";
}
.icon-flower_cat:before {
  content: "\e9f3";
}
.icon-food_cat:before {
  content: "\e9f4";
}
.icon-grocery_cat:before {
  content: "\e9f5";
}
.icon-more:before {
  content: "\e9f6";
}
.icon-pharmacy_cat:before {
  content: "\e9f7";
}
.icon-stationery_cat:before {
  content: "\e9f8";
}
.icon-supermarket_cat:before {
  content: "\e9f9";
}
.icon-water-bottle_cat:before {
  content: "\e9fa";
}
.icon-stores-chain:before {
  content: "\e9ee";
}
.icon-grocery-online:before {
  content: "\e9ef";
}
.icon-cloud-store:before {
  content: "\e9f0";
}
.icon-promo_code:before {
  content: "\e9ec";
}
.icon-god-eye:before {
  content: "\e9ed";
}
.icon-web-panel:before {
  content: "\e9e6";
}
.icon-features:before {
  content: "\e9e7";
}
.icon-application-flow:before {
  content: "\e9e8";
}
.icon-more-info:before {
  content: "\e9e9";
}
.icon-purchase:before {
  content: "\e9ea";
}
.icon-customizations:before {
  content: "\e9eb";
}
.icon-chat:before {
  content: "\e9e5";
}
.icon-medicine_fill:before {
  content: "\e9d4";
}
.icon-food-delivery_fill:before {
  content: "\e9d5";
}
.icon-delivery_genie_fill:before {
  content: "\e9d6";
}
.icon-multi_delivery:before {
  content: "\e9d7";
}
.icon-car-sharing_fill:before {
  content: "\e9d8";
}
.icon-moto_fill:before {
  content: "\e9d9";
}
.icon-intercity_fill:before {
  content: "\e9da";
}
.icon-book_later_fill:before {
  content: "\e9db";
}
.icon-suitcase_fill:before {
  content: "\e9dc";
}
.icon-taxi_bid_fill:before {
  content: "\e9dd";
}
.icon-fruits_fill:before {
  content: "\e9de";
}
.icon-supermarket_store_fill:before {
  content: "\e9df";
}
.icon-flower_fill:before {
  content: "\e9e0";
}
.icon-grocery_fill:before {
  content: "\e9e1";
}
.icon-single-parcel-delivery:before {
  content: "\e9e2";
}
.icon-delivery-runner_fill:before {
  content: "\e9e3";
}
.icon-car_rent_fill:before {
  content: "\e9e4";
}
.icon-arrow_right_thin:before {
  content: "\e9cf";
}
.icon-clients:before {
  content: "\e9d0";
}
.icon-hat:before {
  content: "\e9d1";
}
.icon-play-arrow:before {
  content: "\e9d2";
}
.icon-satisfied-customer:before {
  content: "\e9d3";
}
.icon-rating:before {
  content: "\e9cb";
}
.icon-salary_3135673:before {
  content: "\e9cc";
}
.icon-certificate_3135763:before {
  content: "\e9cd";
}
.icon-chair_2157306:before {
  content: "\e9ce";
}
.icon-manage-gallery:before {
  content: "\e9ca";
}
.icon-organization_panel:before {
  content: "\e9c8";
}
.icon-taxi-dispatch:before {
  content: "\e9c9";
}
.icon-front_end:before {
  content: "\e9c5";
}
.icon-mobile_application:before {
  content: "\e9c6";
}
.icon-admin_panel:before {
  content: "\e9c7";
}
.icon-play-button:before {
  content: "\e9c2";
}
.icon-right-arrow:before {
  content: "\e9c3";
}
.icon-left-arrow:before {
  content: "\e9c4";
}
.icon-briefcase:before {
  content: "\e9c1";
}
.icon-store:before {
  content: "\e9be";
}
.icon-healthy-food:before {
  content: "\e9bf";
}
.icon-flowers:before {
  content: "\e9c0";
}
.icon-demo-app:before {
  content: "\e9ba";
}
.icon-white-label:before {
  content: "\e9bb";
}
.icon-application-store:before {
  content: "\e9bc";
}
.icon-home-check:before {
  content: "\e9bd";
}
.icon-delivery-truck:before {
  content: "\e9b6";
}
.icon-fork:before {
  content: "\e9b7";
}
.icon-standard-taxi:before {
  content: "\e9b8";
}
.icon-moto:before {
  content: "\e9b9";
}
.icon-color-theme:before {
  content: "\e999";
}
.icon-inter-city:before {
  content: "\e99a";
}
.icon-ride-bidding:before {
  content: "\e99b";
}
.icon-subscription-plan:before {
  content: "\e99c";
}
.icon-taxi-placeholder:before {
  content: "\e99d";
}
.icon-x-to-y:before {
  content: "\e99e";
}
.icon-app-launched:before {
  content: "\e99f";
}
.icon-award:before {
  content: "\e9a0";
}
.icon-employee:before {
  content: "\e9a1";
}
.icon-medicines:before {
  content: "\e9a2";
}
.icon-grocery:before {
  content: "\e9a3";
}
.icon-delivery_process:before {
  content: "\e9a4";
}
.icon-effective_experience:before {
  content: "\e9a5";
}
.icon-efective_solution:before {
  content: "\e9a6";
}
.icon-manager:before {
  content: "\e9a7";
}
.icon-privacy:before {
  content: "\e9a8";
}
.icon-update:before {
  content: "\e9a9";
}
.icon-rejectection-support:before {
  content: "\e9aa";
}
.icon-source-code:before {
  content: "\e9ab";
}
.icon-app:before {
  content: "\e9ac";
}
.icon-delivery-rating:before {
  content: "\e9ad";
}
.icon-payment-gateway:before {
  content: "\e9ae";
}
.icon-currencies:before {
  content: "\e9af";
}
.icon-order-tracking:before {
  content: "\e9b0";
}
.icon-multi-stores:before {
  content: "\e9b1";
}
.icon-single-store:before {
  content: "\e9b2";
}
.icon-contactless-delivery:before {
  content: "\e9b3";
}
.icon-take-away:before {
  content: "\e9b4";
}
.icon-doorstep_delivery:before {
  content: "\e9b5";
}
.icon-biometric:before {
  content: "\e989";
}
.icon-credit-card:before {
  content: "\e942";
}
.icon-login:before {
  content: "\e984";
}
.icon-reward:before {
  content: "\e985";
  color: #474747;
}
.icon-social-media-advertisement:before {
  content: "\e986";
}
.icon-video-call:before {
  content: "\e987";
}
.icon-adwords:before {
  content: "\e988";
}
.icon-alert-request:before {
  content: "\e93a";
}
.icon-appointment:before {
  content: "\e93b";
}
.icon-baby:before {
  content: "\e93c";
}
.icon-barcode:before {
  content: "\e93d";
}
.icon-call-masking:before {
  content: "\e93e";
}
.icon-call-msg-user:before {
  content: "\e93f";
}
.icon-cancel-delivery:before {
  content: "\e940";
}
.icon-cancel-trip:before {
  content: "\e941";
}
.icon-car-sharing:before {
  content: "\e943";
}
.icon-contactless-delivery1:before {
  content: "\e944";
  color: #565656;
}
.icon-corporate-ride:before {
  content: "\e945";
}
.icon-customer-support:before {
  content: "\e946";
}
.icon-destination-later:before {
  content: "\e947";
}
.icon-dis-order:before {
  content: "\e948";
}
.icon-driver:before {
  content: "\e949";
}
.icon-earning:before {
  content: "\e94a";
}
.icon-email-phone-login:before {
  content: "\e94b";
}
.icon-emr-contact:before {
  content: "\e94c";
}
.icon-estimetable:before {
  content: "\e94d";
}
.icon-fare-estimation:before {
  content: "\e94e";
}
.icon-feedback:before {
  content: "\e94f";
}
.icon-feedback1:before {
  content: "\e950";
}
.icon-food-delivery:before {
  content: "\e951";
}
.icon-food-order:before {
  content: "\e952";
}
.icon-gender:before {
  content: "\e953";
}
.icon-geo-fancing:before {
  content: "\e954";
}
.icon-internal-chat:before {
  content: "\e955";
}
.icon-invitation:before {
  content: "\e956";
}
.icon-maintenance:before {
  content: "\e957";
}
.icon-manage-document:before {
  content: "\e958";
}
.icon-manage-profile:before {
  content: "\e959";
}
.icon-mana-order-list:before {
  content: "\e95a";
}
.icon-map-navigator:before {
  content: "\e95b";
}
.icon-menu:before {
  content: "\e95c";
}
.icon-mobile-phone:before {
  content: "\e95d";
}
.icon-moto-rental:before {
  content: "\e95e";
}
.icon-multiple-services:before {
  content: "\e95f";
}
.icon-multiple-stopover:before {
  content: "\e960";
}
.icon-museum:before {
  content: "\e961";
}
.icon-note:before {
  content: "\e962";
}
.icon-notebook:before {
  content: "\e963";
}
.icon-notification:before {
  content: "\e964";
}
.icon-online-money:before {
  content: "\e965";
}
.icon-online-pharmacy:before {
  content: "\e966";
}
.icon-order-checkout:before {
  content: "\e967";
}
.icon-order-cost:before {
  content: "\e968";
}
.icon-parcel-send:before {
  content: "\e969";
}
.icon-pricing:before {
  content: "\e96a";
}
.icon-promocode:before {
  content: "\e96b";
}
.icon-promotion:before {
  content: "\e96c";
}
.icon-reception:before {
  content: "\e96d";
}
.icon-refer:before {
  content: "\e96e";
}
.icon-rental:before {
  content: "\e96f";
}
.icon-restaurent:before {
  content: "\e970";
}
.icon-review:before {
  content: "\e971";
}
.icon-ride:before {
  content: "\e972";
}
.icon-schedule-booking:before {
  content: "\e973";
}
.icon-social-media:before {
  content: "\e974";
}
.icon-someone-else:before {
  content: "\e975";
}
.icon-sos:before {
  content: "\e976";
}
.icon-surcharge:before {
  content: "\e977";
}
.icon-take-away1:before {
  content: "\e978";
}
.icon-taxi:before {
  content: "\e979";
}
.icon-taxi-booking-app:before {
  content: "\e97a";
}
.icon-taxi-driver:before {
  content: "\e97b";
}
.icon-toll-road:before {
  content: "\e97c";
}
.icon-tow-truck:before {
  content: "\e97d";
}
.icon-tracking1:before {
  content: "\e97e";
}
.icon-vehicle-options:before {
  content: "\e97f";
}
.icon-wallet3:before {
  content: "\e980";
}
.icon-wallet-credit-pay:before {
  content: "\e981";
}
.icon-wheelchair:before {
  content: "\e982";
}
.icon-x-y-location:before {
  content: "\e983";
}
.icon-quality:before {
  content: "\e91c";
}
.icon-respect:before {
  content: "\e91d";
}
.icon-shopping:before {
  content: "\e91e";
}
.icon-like:before {
  content: "\e91f";
}
.icon-padlock:before {
  content: "\e920";
}
.icon-database:before {
  content: "\e921";
}
.icon-free:before {
  content: "\e922";
}
.icon-privacy-policy:before {
  content: "\e924";
}
.icon-processing:before {
  content: "\e925";
}
.icon-upgrade:before {
  content: "\e926";
}
.icon-application:before {
  content: "\e901";
}
.icon-app1:before {
  content: "\e900";
}
.icon-booking:before {
  content: "\e902";
}
.icon-cancel:before {
  content: "\e903";
}
.icon-computer:before {
  content: "\e904";
}
.icon-dashboard:before {
  content: "\e905";
}
.icon-license:before {
  content: "\e906";
}
.icon-local:before {
  content: "\e907";
}
.icon-money:before {
  content: "\e908";
}
.icon-nda:before {
  content: "\e909";
}
.icon-source-code1:before {
  content: "\e90a";
}
.icon-stamp:before {
  content: "\e90b";
}
.icon-support:before {
  content: "\e90c";
}
.icon-translate:before {
  content: "\e90d";
}
.icon-update1:before {
  content: "\e90e";
}
.icon-wallet:before {
  content: "\e90f";
}
.icon-web:before {
  content: "\e910";
}
.icon-advanced-analytics:before {
  content: "\e911";
}
.icon-printer:before {
  content: "\e912";
}
.icon-statistics:before {
  content: "\e913";
}
.icon-transfer-money:before {
  content: "\e914";
}
.icon-wallet1:before {
  content: "\e915";
}
.icon-wallet2:before {
  content: "\e916";
}
.icon-file:before {
  content: "\e917";
}
.icon-mobile:before {
  content: "\e918";
}
.icon-order:before {
  content: "\e919";
}
.icon-programmer:before {
  content: "\e91a";
}
.icon-website:before {
  content: "\e91b";
}
.icon-massage:before {
  content: "\e923";
}
.icon-dashboard1:before {
  content: "\e927";
}
.icon-tracking:before {
  content: "\e928";
}
.icon-car:before {
  content: "\e929";
}
.icon-meal:before {
  content: "\e92a";
}
.icon-shop:before {
  content: "\e92b";
}
.icon-uniE92C:before {
  content: "\e92c";
}
.icon-uniE92D:before {
  content: "\e92d";
}
.icon-shopping-cart:before {
  content: "\e92e";
}
.icon-tracking-location:before {
  content: "\e92f";
}
.icon-submit:before {
  content: "\e930";
}
.icon-payment:before {
  content: "\e931";
}
.icon-panic-sos-emergency-button:before {
  content: "\e932";
}
.icon-face-scan:before {
  content: "\e933";
}
.icon-ride-now-or-ride-later-copy:before {
  content: "\e934";
}
.icon-gift:before {
  content: "\e935";
}
.icon-geolocation:before {
  content: "\e936";
}
.icon-Doorstep:before {
  content: "\e937";
}
.icon-biding:before {
  content: "\e938";
}
.icon-uiux1:before {
  content: "\e939";
}
.icon-fill-carrent:before {
  content: "\e98a";
}
.icon-fill-store:before {
  content: "\e98b";
}
.icon-fill-nearby:before {
  content: "\e98c";
}
.icon-fill-genral-item:before {
  content: "\e98d";
}
.icon-fill-medical-services:before {
  content: "\e98e";
}
.icon-fill-tracking-famaily-and-friends:before {
  content: "\e98f";
}
.icon-fill-carpoll:before {
  content: "\e990";
}
.icon-filll-real-estae:before {
  content: "\e991";
}
.icon-fill-hanymna:before {
  content: "\e992";
}
.icon-fill-video-conslutions:before {
  content: "\e993";
}
.icon-fill-bid:before {
  content: "\e994";
}
.icon-fill-genine:before {
  content: "\e995";
}
.icon-fill-food:before {
  content: "\e996";
}
.icon-fill-parcel:before {
  content: "\e997";
}
.icon-fill-taxi:before {
  content: "\e998";
}



.buy-button,
header {
    transition: all .5s ease 0s;
    -webkit-transition: all .5s ease 0s;
    -moz-transition: all .5s ease 0s;
    -ms-transition: all .5s ease 0s;
    -o-transition: all .5s ease 0s
}
.scroll-downs {
    position: absolute;
    top: 50%;
    right: 10px;
    bottom: auto;
    margin: auto;
    z-index: 1;
    width: 24px;
    height: 45px;
    display: none;
    transform: translateY(-50%);
    transform: -o-translateY(-50%);
    transform: -moz-translateY(-50%);
    transform: -webkit-translateY(-50%);
}
.mousey {
    width: 3px;
    padding: 3px 9px;
    height: 35px;
    border: 1.5px solid #000;
    border-radius: 25px;
    opacity: .75;
    box-sizing: content-box
}
.scroller {
    width: 2px;
    height: 10px;
    border-radius: 25%;
    background-color: #000;
    animation-name: scroll;
    animation-duration: 2.2s;
    animation-timing-function: cubic-bezier(.15, .41, .69, .94);
    animation-iteration-count: infinite
}
@keyframes scroll {
    0% {
        opacity: 0
    }
    10% {
        opacity: 1;
        transform: translateY(0);
        -webkit-transform: translateY(0);
        -moz-transform: translateY(0);
        -ms-transform: translateY(0);
        -o-transform: translateY(0)
    }
    100% {
        opacity: 0;
        transform: translateY(15px);
        -webkit-transform: translateY(15px);
        -moz-transform: translateY(15px);
        -ms-transform: translateY(15px);
        -o-transform: translateY(15px)
    }
}
.banner-caption .high-light,
.banner-caption p b,
.banner-sub,
.bottom-caption h2,
.buy-button,
.gen-button,
.header-right ul li a,
.home-banner-part-left a {
    text-transform: uppercase
}
.buy-product-section ul li img,
.corevalues-section ul li img,
.process-section ul li img {
    max-height: 52px
}
header {
    position: fixed !important;
    width: 100%;
    top: 0;
    z-index: 1111;
    left: 0;
    right: 0;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, .2);
    background-color: #fff
}
header.passive .buy-button:hover,
header.trans .buy-button:hover {
    background-color: #000;
    color: #fff
}
header.passive,
header.trans {
    background-color: #fff;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, .2)
}
header.passive .header-right ul li a,
header.trans .header-right ul li a {
    color: #000;
}
header.passive .header-right>ul>li>a:after,
header.trans .header-right>ul>li>a:after {
    border-top-color: #000
}
.header-cont {
    max-width: 1356px;
    margin: 0 auto
}
.header-inner {
    padding: 13px 15px;
    display: flex;
    justify-content: space-between;
    position: relative
}
header .logo {
    display: inline-block
}
header .logo img {
  vertical-align: top;
}
.header-right {
  margin-left: auto;
}
.header-right ul li ul.drop-menu.sub-menu1 {
    left: 250px;
    top: -16px
}
.header-right ul li ul.drop-menu.sub-menu1:before {
    border-left: 10px solid #fff;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    left: -20px;
    right: auto;
    top: 22px
}
.menu-icon {
    width: 30px;
    height: 23px;
    background: 0 0;
    border: none;
    position: relative;
    display: none;
    justify-content: center;
    align-items: center;
    padding: 0;
    margin: 0;
    cursor: pointer;
}
.menu-icon span:after,
.menu-icon span:before {
    position: absolute;
    width: 100%;
    height: 3px;
    background-color: #fff;
    left: 0;
    content: ''
}
.menu-icon span {
    display: block;
    width: 100%;
    height: 3px;
    background-color: #fff
}
.menu-icon span:before {
    top: 0
}
.menu-icon span:after {
    bottom: 0
}
.manuflexible {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
.header-right ul {
    display: inline-block;
    padding: 0;
    font-size: 0;
}
.header-right ul.adjustment {
    max-width: 830px;
    max-height: 16px; 
    overflow: hidden;
}
.header-right ul li a {
    display: inline-block;
    list-style: none;
    text-decoration: none;
    font-size: 13px;
    color: #000;
    font-weight: 600;
    cursor: pointer;
    font-family: 'Exo 2', sans-serif
}
.header-right ul li a.active_menu {
    color: #000
}
.header-right ul li {
    display: inline-block;
    margin-right: 15px;
    position: relative
}
.header-right ul li.hilight-menu a {
    font-weight: 800
}
.header-right ul li ul.drop-menu {
    position: absolute;
    top: 95%;
    padding: 15px 0 0;
    width: 260px;
    display: none;
    filter: drop-shadow(0 6px 12px rgba(0, 0, 0, .175));
    -webkit-filter: drop-shadow(0 6px 12px rgba(0, 0, 0, .175));
    z-index: 1;
}
.header-right ul li ul.drop-menu.sub-menu1 {
    display: none
}
.header-right ul li ul.drop-menu li {
    display: block;
    padding: 0;
    background-color: #fff;
    margin: 0
}
.header-right ul li ul.drop-menu li a {
    display: block;
    padding: 11px 20px 10px;
    color: #000;
    font-size: 13px;
    text-transform: uppercase;
    font-weight: 500;
    position: relative;
    line-height: normal;
}
.header-right ul li ul.drop-menu li a.menu-active {
    background-color: #e4e4e4;
    color: #000
}
.header-right ul li ul.drop-menu li a.highlight {
    background-color: #ff6e0060
}
.header-right ul li ul.drop-menu li a.highlightgray {
    background-color: #eee
}
.header-right ul li ul.drop-menu li a.highlight.green {
    background-color: #51c40660
}
.header-right ul li ul.drop-menu li a.highlight.green:hover {
    background-color: #e4e4e4
}
.header-right ul li ul.drop-menu li a:hover {
    background-color: #e4e4e4
}
.buy-button {
    font-size: 15px;
    font-family: 'exo 2';
    color: #fff;
    text-decoration: none;
    background-color: #ff6e00;
    padding: 12px 9px;
    border-radius: 3px;
    font-weight: 500;
    display: inline-block;
    position: relative;
    top: 6px
}
.buy-button:hover {
    background-color: #000;
    color: #fff
}
.header-right ul li ul.drop-menu li a.lightgray {
    background-color: lightgray;
}
.header-right ul li ul.drop-menu li a.lightgray:hover {
    background-color: #fff;
}


/********************** Footer CSS Start *********************************/
footer {
    float: left;
    width: 100%;
    display: block;
}
.footer-build-image {
    display: block;
}
.footer-build-image.half_width img {
  width: 50%;
}
.footer-build-image.tenth_part img {
  width: 10%;
  height: 644px;
}
.footer-build-image.half_width {
  display: flex;
  flex-wrap: wrap;
}
.footer-build-image img {
    width: 100%;
}
.footer-top {
    padding: 30px 0 0 0;
}
.footer-top .footer-innter{
  justify-content: center;
}
.footer-top:empty {
  display: none;
}
.footer-innter {
    max-width: 1310px;
    padding: 0 15px;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    line-height: 18px;
    font-size: 14px;
    color: #000;
}
.footer-col {
    padding: 0 10px;
    box-sizing: border-box;
}
footer ul.listing {
    padding: 0;
    margin: 0;
    list-style-type: none;
}
footer b.blog-title-holder ~ ul.listing {
  margin-top: -6px;
}
footer ul.listing li {
    color: #fc4d00;
    margin-bottom: 0;
    padding:0 0 0 15px;
    position:relative;
    line-height: normal;
}
footer ul.listing li:last-child {
    margin-bottom:0;
}
footer ul.listing li:before {
    content: '';
    position: absolute;
    width: 6px;
    height: 6px;
    background-color: #FF6E00;
    border-radius: 50%;
    left: 0;
    top: 7px;
    background-image: none;
}
footer ul.listing li a {
    text-decoration: none;
    color: #383838;
    font-size: 15px;
    font-weight: 500;
    font-family: 'Exo 2';
    text-transform: initial;
}
.footer_links {
    display: flex;
    flex-wrap: wrap;
    gap: 15px
}
.footer_links li::before{
  display: none;
}

footer .footer-col strong {
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 15px;
  display: block;
  text-transform: uppercase;
  line-height: normal;
  text-align: center;
}
footer .flexrow {
    display: flex;
    flex-wrap: wrap;
}
.footer-col .listing.footer_links{
    display: flex;
    flex-wrap: wrap;
    gap: 7px 13px;
}
footer ul.footer_links li{
  padding-left: 0;
  margin: 0;
  width: auto;
}
footer ul.footer_links li:not(:last-child) {
  border-right: 1px solid #ccc;
  padding-right: 15px;
}
footer ul.footer_links li a{
  color: #000;
  font-size: 16px;
}
.footer_links li a:hover{
  color: #fc4d00;
}
.footer-middle {
    padding: 30px 0 0 0;
}
footer b {
    font-size: 17px;
    color: #000;
    font-weight: 700;
    margin-bottom: 10px;
    display: block;
}
footer b a {
    font-size: 15px;
}
.footer-dmca a {
    display: inline-block;
    margin: 0 5px;
    vertical-align: middle;
}
.footer-dmca a img {
    vertical-align: top;
}
footer .footer-col b {text-transform: uppercase;}
footer ul.blanklist {
    padding: 0;
    margin: 0;
    list-style-type: none;
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
}
footer ul.blanklist li a {
    font-size: 20px;
    color: #eb6f12;
    font-weight: 500;
    text-decoration: none;
    transition:all 0.5s ease 0s;
}
footer ul.blanklist li a small {
    font-size: 14px;
    color: #a0a0a0;
    line-height: normal;
    font-weight: 300;
    display: inline-block;
    margin-left: 4px;
}
footer .footerdatablock {
    margin-bottom: 20px;
}
footer .footer-col .addressblock strong {
    font-size: 16px;
    font-weight: 700;
    color: #44ac00;
    text-transform: uppercase;
    margin-bottom: 0;
    margin-top: 8px;
    text-align:left;
}
footer .addressblock span {
    font-style: normal;
    font-size: 15px;
    color: #000;
    font-weight: 400;
    text-transform: none;
    margin: 6px 0 6px 0;
    display: block;
}
footer .addressblock address {
    color: #545454;
    font-size: 15px;
    font-weight: 300;
    font-style: normal;
    max-width: 210px;
    line-height: 25px;
}
footer .addressblock .indiaflag {
    position: absolute;
    left: 0;
    top: 0;
}
footer .addressblock {
    position: relative;
    padding: 0 0 0 52px;
}
.footer-middle .footer-col {
    width: 25%;
    padding: 0 15px;
    box-sizing: border-box;
}
.footerdatablock a {
    font-size: 15px;
    color: #44ac00;
    text-decoration: none;
}
footer .footerdatablock b ~ a[title="sales@v3cube.com"] {
  margin-top: -5px;
  display: block;
}
.footerdatablock a:hover {
    color:#545454;
}
footer b.blog-title-holder {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
footer b.blog-title-holder a {
    color: #FF6E00;
    text-decoration: none;
}

.footer-bottom {
    text-align: center;
    border-top: 1px solid #cecece;
    padding: 20px 0;
    margin-top: 40px;
}
.footer-bottom b.footer-dmca {
    text-align: center;
    display: block;
    width: 100%;
    margin: 10px 0 4px;
}
.footer-bottom .copyright {
    font-size: 13px;
    color: #000;
    float: left;
    width: 100%;
    margin-top: 20px;
}
.footer-dmca p:last-child {
    margin: 8px 0 0 0;
    text-align: center;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
}
.footer-bottom p {
    color: #000;
    font-size: 14px;
    line-height: 22px;
}
footer .p-text {
    text-align: center;
    font-weight: 500;
    color: #000;
    font-size: 16px;
    line-height: 20px;
    margin: 0px;
}
.footer-middle .footer-col img {
    max-width:100%;
}
.footer-bottom .copyright a {
    color: #FF6E00;
    text-decoration: none;
}
.copy-flex {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 10px;
}
.domainname {
    font-size: 41px;
    font-weight: 600;
    color: #fc4d00;
    text-align: center;
    text-transform: uppercase;
    padding: 0;
    text-align: center;
    float: left;
    width: 100%;
}
.domainname:after {
    content: "";
    background: #fc4d00;
    width: 75px;
    height: 1px;
    margin: 15px auto 15px;
    display: block;
}
.footer-middle .footer-col:nth-child(2) {
    width: 30%;
}
.footer-middle .footer-col:nth-child(3) {
    width: 20%;
}
/************************** Footer CSS End *****************************/
.popupform .slider-popup {
    margin: 0;
    padding: 10px 10px 10px 40px;
    width: 100%;
    background: #fff;
    border: 1px solid #ECECEC;
    color: #000;
    font-size: 14px;
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
}
.popupform .phone .slider-popup:nth-child(2) {
    width: 140px;
    border-radius: 5px 0 0 5px;
    -webkit-border-radius: 5px 0 0 5px;
    -moz-border-radius: 5px 0 0 5px;
    -ms-border-radius: 5px 0 0 5px;
    -o-border-radius: 5px 0 0 5px;
}
.popupform .phone .slider-popup:last-child {
    width: calc(100% - 140px);
    width: -o-calc(100% - 140px);
    width: -moz-calc(100% - 140px);
    width: -webkit-calc(100% - 140px);
    border-radius: 0 5px 5px 0;
    -webkit-border-radius: 0 5px 5px 0;
    -moz-border-radius: 0 5px 5px 0;
    -ms-border-radius: 0 5px 5px 0;
    -o-border-radius: 0 5px 5px 0;
    padding: 10px;
    border-width: 1px 1px 1px 0;
}
.form-holder {
    position: relative;
    display: block;
    margin-bottom: 10px;
    width: 100%;
}
.form-holder > img {
    position: absolute;
    height: 15px;
    left: 13px;
    top: 11px;
}
 .popupform select {
    color: #666;
    min-height: 39px;
    box-sizing: border-box;
    padding: 7px 30px 9px 40px;
    width: 100%;
    appearance: none;
    -webkit-appearance: none;
    background-image: url(../images/form-icons/drop_arrow.svg);
    background-repeat: no-repeat;
    background-position: center right 12px;
    background-size: 15px;
    font-size: 14px;
 }
 .popupform textarea {
     height: 100px;
     resize: vertical;
 }
 .popupform .popupform1 img {
     float: left;
     margin: 0 5px 0 0;
     height: 37px;
 }
 .popupform .popupform1 .chapcha {
     width: 58%;
 }
 .button-pu {
     margin: 2px 0 0 4px;
     padding: 4px 6px;
     background: #000;
     border: none;
     color: #a0b03b;
     cursor: pointer;
     text-transform: uppercase;
     font-size: 11px;
     font-weight: 700;
 }
 #sucmsg {
    font-size: 20px;
    margin: 0;
    color: #44ac00;
    text-align: center;
    font-weight: 600;
 }
 .display-status {
    min-height: 292px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
 }
.form-table {
    margin: 0;
    padding: 10px;
    position: fixed;
    right: 30px;
    bottom: -1px;
    width: 350px;
    background-color: #fff;
    outline: 1px solid #ddd;
    transform: translateY(100%);
    -webkit-transform: translateY(100%);
    -moz-transform: translateY(100%);
    -ms-transform: translateY(100%);
    -o-transform: translateY(100%);
    transition: all 0.5s ease 0s;
    -webkit-transition: all 0.5s ease 0s;
    -moz-transition: all 0.5s ease 0s;
    -ms-transition: all 0.5s ease 0s;
    -o-transition: all 0.5s ease 0s;
    z-index: 1111;
}
.form-table.open {
    transform: translatey(0);
    -webkit-transform: translatey(0);
    -moz-transform: translatey(0);
    -ms-transform: translatey(0);
    -o-transform: translatey(0);
}
.form-table.open .close_btn {
    font-size: 30px;
    width: 36px;
    height: 36px;
    display: flex;
    justify-content: center;
    border-radius: 50%;
    box-shadow: 0 0 5px 0 rgba(0,0,0,0.2);
    align-items: center;
    right: -20px;
    top: -22px;
    background-color: #fff;
    padding: 0;
}
.close_btn > img.message_icon {
    width: 20px;
    vertical-align: top;
    margin: 0 4px 0 0;
    position: relative;
    top: 3px;
}
.close_btn > i {
  width: 20px;
  vertical-align: top;
  margin: 0 1px 0 0;
  position: relative;
  top: 3px;
  font-size: 14.5px;
}
.popupform {
    border: 0;
    margin: 0;
    padding: 0;
}
.popupform .popup-header {
    background: linear-gradient(180deg, #FF6E00 0%, rgba(255, 110, 0, 0.71) 100%);
    margin: -10px -10px 10px -10px;
    padding: 10px 10px 0 10px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-end;
}
.popupform .popup-header img {
    width: 80px;
}
.popupform .popup-header p {
    color: #fff;
    font-size: 13px;
    line-height: 17px;
    width: calc(100% - 90px);
    width: -o-calc(100% - 90px);
    width: -moz-calc(100% - 90px);
    width: -webkit-calc(100% - 90px);
}
.form-table .popupform .button-pu {
    margin: 0;
    padding: 8px 0;
    background: linear-gradient(180deg, #FF6E00 0%, rgba(255, 110, 0, 0.71) 100%);
    color: #fff;
    width: 100%;
    font-size: 16px;
    text-align: center;
    border-radius: 4px ;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    transition: all 0.5s ease 0s;
    -webkit-transition: all 0.5s ease 0s;
    -moz-transition: all 0.5s ease 0s;
    -ms-transition: all 0.5s ease 0s;
    -o-transition: all 0.5s ease 0s;
    text-transform: capitalize;
    font-weight: 600;
}
.form-table .popupform .button-pu:hover {
    background: linear-gradient(180deg, rgba(255, 110, 0, 0.71) 0%, #FF6E00 100%);
}
.form-table .popupform .button-pu img {
    width: 15px;
    margin-left: 4px;
    vertical-align: middle;
}
.msg-icon {
    margin: 7px 0 0;
    padding: 0;
}
select:focus,
textarea:focus {
    background: 0 0
}
.close_btn {
    margin: 0;
    padding: 10px 15px 10px 15px;
    position: absolute;
    top: -38px;
    display: inline-block;
    border: none;
    background-color: #ff6e00;
    color: #fff;
    font-size: 15px;
    right: 10px;
    border-radius: 5px 5px 0 0;
    -webkit-border-radius: 5px 5px 0 0;
    -moz-border-radius: 5px 5px 0 0;
    -ms-border-radius: 5px 5px 0 0;
    -o-border-radius: 5px 5px 0 0;
    cursor: pointer;
    font-family: 'Exo 2';
    text-transform: uppercase;
    font-weight: 600;
}
.close_btn1 {
     left: -38px
}

.inq_form .input-row-flow .custom-input-box {
  font-family: 'Exo 2';
}
@media screen and (max-width:1330px) {
    .client-event-section .highlight2 {
        font-size: 5vw
    }
    .real-clients-section-left:before {
        width: calc(100% + 30px);
        -o-width: calc(100% + 30px);
        -ms-width: calc(100% + 30px);
        -moz-width: calc(100% + 30px);
        -webkit-width: calc(100% + 30px)
    }
    .real-clients-section-left {
        width: 500
    }
    .real-clients-section-right {
        width: calc(100% - 512px);
        width: -o-calc(100% - 512px);
        width: -ms-calc(100% - 512px);
        width: -moz-calc(100% - 512px);
        width: -webkit-calc(100% - 512px)
    }
    .products .passanger-img {
        width: 39.25vw;
        right: -7vw
    }
    .header-right ul li {
        margin-right: 13px
    }
    .header-right ul li a {
        font-size: 12px
    }
}
@media screen and (min-width:1025px) {
    .header-right ul li:hover>ul.drop-menu {
        display: block
    }
    .header-right>ul>li>a.menu-active:after,
    .header-right>ul>li>a:hover:after {
        content: '';
        position: absolute;
        width: 100%;
        height: 3px;
        background-color: #51c406;
        bottom: -11px;
        left: 0
    }
}
@media screen and (max-width:1024px) {
    .header-inner {
        display: block;
        padding: 14px 15px 0
    }
    .site-header .mobile-logo {
        float: left
    }
    .menu-icon {
        display: flex;
        position: absolute;
        right: 15px;
        top: 27px;
        outline: 0;
        box-shadow: none
    }
    .header-right {
        margin: 0 -15px;
        background-color: #fff;
        display: none;
        border-top: 1px solid #ddd
    }
    header .logo {
        margin-bottom: 14px;
        min-height: 52px;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
    }
    .header-right ul {
        display: block;
    }
    .header-right ul li {
        display: block;
        margin-right: 0
    }
    .header-right ul li a {
        padding: 8px 15px;
        display: block;
        color: #000;
        border-bottom: 1px solid #ddd;
        font-size: 15px
    }
    .header-right .buy-button {
        display: block;
        border-radius: 0;
        background-color: #ffeada;
        color: #000;
    }
    .header-right>ul>li>a.has-submenu:after {
        right: 15px;
        top: 13px
    }
    .header-right>ul>li>a.has-submenu:after {
        content: '';
        position: absolute;
        border-top: 8px solid #000;
        border-left: 7px solid transparent;
        border-right: 7px solid transparent
    }
    .header-right>ul>li>a.has-submenu.active-menu:after {
        content: '';
        position: absolute;
        border-top: 0 solid #000;
        border-bottom: 8px solid #000;
        border-left: 7px solid transparent;
        border-right: 7px solid transparent;
        top: 13px
    }
    .header-right ul li ul.drop-menu {
        position: relative;
        padding-top: 0;
        max-width: unset;
        width: 100%;
        filter: drop-shadow(0 0 0 rgba(0, 0, 0, .4));
        -webkit-filter: drop-shadow(0 0 0 rgba(0, 0, 0, .4))
    }
    .header-right ul li ul.drop-menu li {
        background-color: #eee
    }
    header {
        background-color: #fff
    }
    .menu-icon span,
    .menu-icon span:after,
    .menu-icon span:before {
        background-color: #000
    }
    .header-right ul li ul.drop-menu li.has-submenu.mob-hide {
        display: none
    }
    .buy-button {
        top: 0
    }
    .buy-button.enqmob {
        margin-right: 45px;
        font-size: 14px;
        padding: 8px 11px;
        margin-top: 10px;
    }
    header .logo img {
        max-height: 52px
    }
    footer ul.footer_links li:not(:last-child){
      border-right: 1px solid #ccc !important;
    }
}
@media screen and (max-height:778px) and (max-width:767px) {
    .header-right ul li ul.drop-menu {
        /* max-height: 160px; */
        overflow-y: auto
    }
    .scroll-downs {
        display: none
    }
}
@media screen and (max-width:991px) {
    .footer-top .footer-col {
        width: 50%;
        margin-bottom: 25px;
    }
    .footer-middle .footer-col,
    .footer-middle .footer-col:nth-child(2),
    .footer-middle .footer-col:nth-child(3) {
        width: 50%;
        margin-bottom: 25px;
    }
    .footer-top .footer-col:last-child {
        width: 100%;
    }
}
@media screen and (max-width:767px) {
    footer ul.blanklist li a {
        font-size: 15px;
    }
    footer ul.blanklist li a small {
        font-size: 12px;
    }
    .domainname {
        font-size: 30px;
    }
    .form-table {
      width: 280px;
    }
    .popupform .phone .slider-popup:nth-child(2) {
        width: 100%;
    }
    .popupform .phone .slider-popup:last-child {
        width: 100%;
        border-width: 0px 1px 1px 1px;
    }
}
@media screen and (max-width:630px) {
    .footer-middle .footer-col.achivements-column {
        text-align: center;
    }
    footer ul.listing:first-child {
        margin-right: 0;
        width: 100%;
        margin-bottom: 10px;
    }
    .footer-middle .footer-col,
    .footer-middle .footer-col:nth-child(2),
    .footer-middle .footer-col:nth-child(3) {
        width:100%;
        padding: 0;
    }
    footer .addressblock address {
      max-width: 240px;
      margin: 0 auto;
    }
    footer .footerdatablock {
        text-align: center;
    }
    footer ul.blanklist {
      justify-content: center;
    }
    header .logo img {
        max-height: 35px;
    }
    footer .addressblock {
      padding: 0;
      text-align: center;
    }
    footer .addressblock .indiaflag {
      position: relative;
    }
    footer .footer-col b {
      text-align: center;
    }
    footer .addressblock address br {
      display: none;
    }
}
@media screen and (max-width:480px) {
    .footer-top .footer-col {
        width:100%;
    }
    footer .footer-col strong {
        font-size: 18px;
    }
    footer .footer-col .addressblock strong {
      text-align: center;
    }
}
@media screen and (max-width:355px) {
    .buy-button.enqmob {
        display: none;
    }
}